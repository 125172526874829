<template>
    <div>
        <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
            <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
            <v-btn dark text @click="snackbar = false">Close</v-btn>
        </v-snackbar>
        <v-row>
            <div style="position: fixed; z-index: 1;  background-color: white; width: 100%; top:55px;">
                <v-breadcrumbs :items="items" large style=" font-weight: 600; letter-spacing: 1px; ">

                </v-breadcrumbs>
            </div>
        </v-row><br><br><br />
        <div v-if="init_loading">
            <v-skeleton-loader v-bind="attrs" type="three-line,  article"></v-skeleton-loader>
        </div>
        <div v-else>
            <v-card class="card">
                <v-card-title class="heading justify-center">
                    <span>Library Configuration</span>
                </v-card-title>
                <v-card-text>
                    <div class="add-section">
                        <div>
                            <v-col>
                                <v-form ref="form">
                                    <label class="add-text">Name</label><span
                                        style="color: red; font-weight: bolder">*</span>
                                    <v-text-field class="text" v-model="libConfig" outlined dense :rules="[rules.required]"
                                        clearable required></v-text-field>

                                    <label class="add-text">Value</label><span
                                        style="color: red; font-weight: bolder">*</span>
                                    <v-text-field class="text" v-model="libConfig_Value" outlined dense
                                        :rules="[rules.required]" clearable required></v-text-field>

                                </v-form>
                            </v-col>
                            <v-row class="justify-center mb-3">
                                <v-btn color="success" @click="addlibConfig">Add</v-btn>
                            </v-row>
                        </div>
                    </div>

                    <template>
                        <div class="text-center">
                            <v-dialog v-model="dialog" width="500">
                                <v-card>
                                    <v-card-title class="grey lighten-2">
                                        Edit Library Configuration
                                    </v-card-title>

                                    <v-card-text style="padding-bottom: 0px;">
                                        <label class="add-text">Name</label>
                                        <v-text-field class="text" v-model="libConfigName" outlined dense
                                            :rules="[rules.required]" clearable required></v-text-field>

                                        <label class="add-text">Value</label>
                                        <v-text-field class="text" v-model="libConfigValue" outlined dense
                                            :rules="[rules.required]" clearable required></v-text-field>
                                    </v-card-text>

                                    <!-- <v-divider></v-divider> -->

                                    <div class="text-center">
                                        <v-spacer></v-spacer>
                                        <v-btn class="m-3" @click="dialog = false">
                                            Close
                                        </v-btn>
                                        <v-btn class="m-3" @click="saveEdit()">Update</v-btn>
                                    </div>
                                </v-card>
                            </v-dialog>
                        </div>
                    </template>

                    <template v-if="libConfig_list != null">
                        <v-data-table :headers="headers" :items="libConfig_list" :search="search"
                            class="elevation-1 nowrap">
                            <template v-slot:top>
                                <v-toolbar flat color="white">
                                    <v-spacer></v-spacer>
                                    <v-text-field v-model="search" append-icon="mdi-magnify" label="Search in table"
                                        single-line hide-details></v-text-field>
                                </v-toolbar>
                            </template>
                            <template v-slot:item.edit="{ item }">
                                <v-icon v-if="item" class="mr-2" @click="updatelibConfig(item)">mdi mdi-pencil-box</v-icon>
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <!-- <v-btn :loading="edit_btn_load" @click="editItem(item)" class="link" color="info"> <v-icon title="Activate/Deactivate the User Type">mdi-recycle</v-icon></v-btn> -->
                                <v-switch v-model="item.libConfigIsactive" @change="editItem(item)"></v-switch>
                            </template>
                        </v-data-table>
                    </template>
                </v-card-text>
            </v-card>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import "@/assets/css/color.css"; // css needs to be imported for each dashboard
export default {
    data: () => ({
        init_loading: false,
        snackbar_msg: "",
        snackbar: false,
        color: "",
        org: null,
        libConfiglist_SA: null,
        dialog: false,
        search: "",
        libConfig: null,
        libConfigValue: null,
        libConfig_Value: null,
        libConfigId: null,
        libConfigName: null,
        userType: null,
        edit_btn_load: false,
        editUserType: null,
        editUserTypeId: null,
        rules: {
            required: (value) => !!value || "Required.",
        },
        items: [
            {
                text: 'Dashboard',
                disabled: false,
                href: '/home',
            },
            {
                text: localStorage.getItem("jhhjbyj"),
                disabled: false,
                href: '/sublink',
            },
            {
                text: 'Library Configuration',
                disabled: true,
                href: 'breadcrumbs_link_2',
            },
        ],
        libConfig_list: null,
        headers: [
            { text: "Sr no", value: "srno" },
            { text: "Name", value: "libConfig" },
            { text: "Value", value: "libConfigValue" },
            { text: "Edit", value: "edit" },
            { text: "Toggle", value: "actions", sortable: false },
        ],
    }),
    watch: {},
    methods: {
        showSnackbar(clr, msg) {
            this.snackbar = true;
            this.color = clr;
            this.snackbar_msg = msg;
        },
        editItem(item) {
            console.log(item);
            axios
                .post("/admin/libConfigIsactive", item)
                .then(() => {
                    // this.sec_mode_list = res.data.user_list;
                    this.showSnackbar("#4CAF50", "Library Configuration updated successfully...");
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.edit_btn_load = false;
                    axios;
                });
        },

        updatelibConfig(item) {
            this.dialog = true;
            this.libConfigName = item.libConfig;
            this.libConfigId = item.libConfigId;
            this.libConfigValue = item.libConfigValue
        },

        saveEdit() {
            const data = {
                libConfigName: this.libConfigName,
                libConfigId: this.libConfigId,
                libConfigValue: this.libConfigValue
            };
            if (this.libConfigName !== null && this.libConfigValue !== null) {
                axios
                    .post("/admin/editLibConfig", data)
                    .then((res) => {
                        this.sec_mode_list = res.data.user_list;
                        this.showSnackbar("#4CAF50", "Library Configuration updated successfully...");
                        this.onload();
                        this.dialog = false;
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => {
                        this.edit_btn_load = false;
                        // this.editUserType = null;
                        axios;
                    });
            } else {
                this.showSnackbar("#b71c1c", "Please enter Required fields");
            }
        },

        onload() {
            this.init_loading = true
            axios
                .post("/admin/getLibConfig")
                .then((res) => {
                    this.org = res.data.org;
                    this.libConfig_list = res.data.libConfig;
                    this.libConfiglist_SA = res.data.libConfiglist_SA
                    console.log('libConfiglist_SA----', this.libConfiglist_SA);
                    this.init_loading = false
                    //this.showSnackbar("#4CAF50","Success")
                })
                .catch((error) => {
                    this.showSnackbar("#b71c1c", "Something went wrong!!!");
                    console.log(error);
                })
                .finally(() => {
                    this.init_loading = false
                });
        },

        addlibConfig() {
            const data = {
                libConfig_name: this.libConfig,
                libConfigValue: this.libConfig_Value
            };

            if (this.$refs.form.validate()) {
                axios
                    .post("/admin/addLibConfig", data)
                    .then((res) => {
                        this.org = res.data.org;
                        this.libConfig_list = res.data.libConfig_list;
                        if (res.data.msg == "success") {
                            this.showSnackbar("#4CAF50", "Library Configuration added successfully...");
                            this.userType = null;
                            this.$refs.form.reset()
                        } else {
                            this.showSnackbar("#b71c1c", res.data.msg);
                        }
                    })
                    .catch((error) => {
                        this.showSnackbar("#b71c1c", "Something went wrong!!!");
                        console.log(error);
                    })
                    .finally(() => { });
            } else {
                this.showSnackbar("#b71c1c", "Please enter required fields");
            }
        },
    },
    mounted() {
        this.onload();
    },
};
</script>
<style scoped>
.heading {
    background-color: #3f51b5;
    padding: 0.3rem;
    color: white;
}

.v-icon {
    cursor: pointer;
}

.text>>>.v-input__slot {
    background: white !important;
    border-color: hsla(190, 99%, 30%, 1) !important;
}
</style>
